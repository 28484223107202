import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../../components/defaultSnippets/Card';
import { useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { selectStudentStatus } from '../../Students/studentSlice';

function PerformanceReportLandingMain() {
    
     const [students, setStudents] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [performanceData, setPerformanceData] = useState({});
    const [performanceDataUexam, setPerformanceDataUexam] = useState({});
    const [searchData, setSearchData] = useState({});
    const studentData = useSelector(selectStudentStatus);
    const [formData, setFormData] = useState({});
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [attendanceData, setAttendanceData] = useState({});
    const navigate = useNavigate();
    const [isUniversityExam, setIsUniversityExam] = useState(false);
    const [uGradeCounts, setUGradeCounts] = useState({});
    const[gradebysubject, setgradeBysubject] = useState({});
const handleMonthRangeChange = (selectedDates) => {
   // alert(selectedDates)
    if (selectedDates.length === 0) {
        console.log("No dates selected");
        setIsButtonEnabled(false);
    } else {
        console.log(selectedDates);
        const formattedDates = selectedDates.map(date => {
            const d = new Date(date);
            return d.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        });

        setSearchData({
            ...searchData,
            perform_month_range: formattedDates,
        });
    }
};
    const semesterOptions = {
        I: ['I', 'II'],
        II: ['III', 'IV'],
        III: ['V', 'VI'],
        IV: ['VII', 'VIII']
        // Add more options based on your requirements
    };

    const fetchStudents = async () => {
        const findid = toast.loading("Fetching Students data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            let res='';
            if(searchData.perform_testtype=="University Exam")
            {
                 res = await axios.get('/api/universityperformancereportstudent', {
                     params: searchData // Pass searchData as query parameters
                });
            }
            else
            {
                 res = await axios.get('/api/performancereportstudent', {
                     params: searchData // Pass searchData as query parameters
                });
            }
           
            let response=''; let startCells=''; let datecells='';
            if(searchData.perform_testtype=="University Exam")
            {
                response = await fetch(`${process.env.PUBLIC_URL}/univperformancestudent.xlsx`);
            }
            else
            {
                response = await fetch(`${process.env.PUBLIC_URL}/performancestudent.xlsx`);
            }
            if (!response.ok) {
                throw new Error("Failed to fetch the Excel template.");
            }

            const workbook = new ExcelJS.Workbook();

            const blob = await response.blob();

            // Load the workbook from the blob
            await workbook.xlsx.load(blob);
            
            const worksheet = workbook.getWorksheet(1); // Get the first worksheet
            console.log(res.data.finalresult);
            const dataArray = Object.values(res.data.result);
            if(searchData.perform_testtype!="University Exam")
            {
            
                datecells=['D8','E8','F8','G8','H8','I8','J8','K8','L8','M8'];
                let rowStart = 9;  let arrears=0; 

                worksheet.getCell("L4").value = {
                    formula: `TEXT(TODAY(), "MMM YYYY")`,
                    result: "null" // This is just for initial display; it will auto-update when the formula runs
                };


                worksheet.getCell(5, 3).value = res.data.academicyear;
                worksheet.getCell(5, 15).value = searchData.academic_year;
                worksheet.getCell(6, 3).value = res.data.coursename;
                worksheet.getCell(6, 15).value = searchData.perform_courseyear+'/'+searchData.perform_semester;
                Object.values(res.data.finalresult).forEach((students) => {
                    let columnStart = 1; // Starting column for each student
                    let colStart=14; let total=0; arrears=0;
                    // Write student general information (single row)
                    worksheet.getCell(rowStart, columnStart).value = students[0].studentid;
                    worksheet.getCell(rowStart, columnStart + 1).value = students[0].studentname;
                    worksheet.getCell(rowStart, columnStart + 2).value = students[0].studentid;
                    
                    // Populate subject details in the next columns
                    students.forEach((subject, index) => {
                        const offset = 2 + (index * 1); // Adjusting columns for each subject
                        worksheet.getCell(datecells[index]).value = subject.subjectcode;
                        worksheet.getCell(rowStart, columnStart + offset + 1).value = Number(subject.marks);
                        total += Number(subject.marks); 
                        if(Number(subject.marks)<50){worksheet.getCell(rowStart, colStart+1).value = arrears++; }else{worksheet.getCell(rowStart, colStart+1).value = arrears;}
                    
                    });

                    //alert(maxmarks);
                    
                    worksheet.getCell(rowStart, colStart).value = total;
                    if(arrears===0)
                    {
                    worksheet.getCell(rowStart, colStart+2).value = 'P';
                    }
                    else
                    {
                        worksheet.getCell(rowStart, colStart+2).value = 'F';
                    }


                    
                    
                    
                    
                    
                    rowStart++;
                });
   

                //header for staff
                worksheet.getCell(rowStart+2, 1).value = "SL.No";
                worksheet.getCell(rowStart+2, 2).value = "Sub. Code";
                
                worksheet.mergeCells(rowStart + 2, 3, rowStart + 2, 13);
                worksheet.getCell(rowStart+2, 3).value = "Subject Title";
                
                worksheet.mergeCells(rowStart + 2, 14, rowStart + 2, 15);
                worksheet.getCell(rowStart+2, 14).value = "Staff Name";
                worksheet.getCell(rowStart+2, 16).value = "Pass %";

                let rowStart2=rowStart+3;
                //alert(rowStart)
                console.log(res.data.staffresult);
                Object.values(res.data.staffresult).forEach((students) => {
                    let columnStart = 1; 
                    console.log(students.staff_performname);
                    worksheet.getCell(rowStart2, columnStart + 1).value = students.staff_performsubjectcode;
                    worksheet.mergeCells(rowStart2, 3, rowStart2, 13);
                    worksheet.getCell(rowStart2, columnStart + 2).value = students.staff_performsubject;
                    worksheet.mergeCells(rowStart2, 14, rowStart2, 15);
                    worksheet.getCell(rowStart2, columnStart + 14).value = students.staff_performname;
                    worksheet.getCell(rowStart2, columnStart + 15).value = Number(students.staff_performpercent);
                    //worksheet.getCell(rowStart2, columnStart).value = students[0].id;
                    //    students.forEach((subject, index) => {
                    //     console.log(subject);
                    //        
                    //    });
                    
                    rowStart2++;
                    rowStart++;
                    
                });

                //header for finalresults
                worksheet.getCell(rowStart2+2, 3).value = "Maximum";
                worksheet.getCell(rowStart2+3, 3).value = "Passed";
                worksheet.getCell(rowStart2+4, 3).value = "Failed";
                worksheet.getCell(rowStart2+5, 3).value = "Absentees";
                worksheet.getCell(rowStart2+6, 3).value = "Appeared";
                worksheet.getCell(rowStart2+7, 3).value = "Percentage";
                let maxmarkstartCol=4;
                Object.values(res.data.maxmarks_results).forEach((marks) => {
                    console.log(marks.max_subjectmarks)
                    worksheet.getCell(rowStart2+2, maxmarkstartCol).value = Number(marks.max_subjectmarks);
                    maxmarkstartCol++;
                });
                let passedmarkstartCol=4;
                Object.values(res.data.passed_results).forEach((passed) => {
                    console.log(passed.students_passed)
                    worksheet.getCell(rowStart2+3, passedmarkstartCol).value = Number(passed.students_passed);
                    passedmarkstartCol++;
                });
                let failedmarkstartCol=4;
                Object.values(res.data.passed_results).forEach((k, passed) => {
                    console.log(k.performance_subjectcode)
                    Object.values(res.data.failed_results).forEach((failed) => {
                    // console.log(passed);
                        console.log(k.performance_subjectcode+"==="+failed.performance_subjectcode)
                        if(k.performance_subjectcode===failed.performance_subjectcode)
                        {
                            worksheet.getCell(rowStart2+4, failedmarkstartCol).value = Number(failed.students_failed);
                            failedmarkstartCol++;
                        }
                        else
                        {
                            worksheet.getCell(rowStart2+4, failedmarkstartCol).value = Number(0);
                            failedmarkstartCol++;
                        }
                    console.log(failed.students_failed)
                    
                    });
                });

                let appearedstartCol=4;
                Object.values(res.data.appeared_results).forEach((appeared) => {
                
                    worksheet.getCell(rowStart2+6, appearedstartCol).value = Number(appeared.total_students);
                    appearedstartCol++;
                });

                worksheet.getCell(rowStart2+7, 4).value = 
                {
                    formula: `(D${rowStart2+3}/D${rowStart2+6})*100`,
                    result: null
                };
                worksheet.getCell(rowStart2+7, 5).value = 
                {
                    formula: `(E${rowStart2+3}/E${rowStart2+6})*100`,
                    result: null
                };
                worksheet.getCell(rowStart2+7, 6).value = 
                {
                    formula: `(F${rowStart2+3}/F${rowStart2+6})*100`,
                    result: null
                };
                worksheet.getCell(rowStart2+7, 7).value = 
                {
                    formula: `(G${rowStart2+3}/G${rowStart2+6})*100`,
                    result: null
                };
                worksheet.getCell(rowStart2+7, 8).value = 
                {
                    formula: `(H${rowStart2+3}/H${rowStart2+6})*100`,
                    result: null
                };
                worksheet.getCell(rowStart2+7, 9).value = 
                {
                    formula: `(I${rowStart2+3}/I${rowStart2+6})*100`,
                    result: null
                };
                worksheet.getCell(rowStart2+7, 10).value = 
                {
                    formula: `(J${rowStart2+3}/J${rowStart2+6})*100`,
                    result: null
                };
                worksheet.getCell(rowStart2+7, 11).value = 
                {
                    formula: `(K${rowStart2+3}/K${rowStart2+6})*100`,
                    result: null
                };

                


                //  Object.values(res.data.failed_results).forEach((failed) => {
                //     console.log(failed.students_failed)
                //      worksheet.getCell(rowStart2+4, failedmarkstartCol).value = Number(failed.students_failed);
                //      failedmarkstartCol++;
                //  });
            }
            else
            {
                alert("Ready to export univ results")
                datecells=['H12','I12','J12','K12','L12','M12','N12','O12'];
                let rowStart = 14;  let rowCount=1;
                Object.values(res.data.finalresult).forEach((students) => {
                      let columnStart = 2; 
                      worksheet.getCell(rowStart, columnStart).value = rowCount;
                      worksheet.mergeCells(rowStart, 3, rowStart, 4);
                      worksheet.getCell(rowStart, columnStart + 2).value = students[0].studentid;
                      worksheet.mergeCells(rowStart, 5, rowStart, 7);
                      worksheet.getCell(rowStart, columnStart + 4).value = students[0].studentname;

                       students.forEach((subject, index) => {
                        const offset = 5 + (index * 1); // Adjusting columns for each subject
                        worksheet.getCell(datecells[index]).value = subject.subjectcode;
                        worksheet.getCell(rowStart, columnStart + offset + 1).value = subject.marks;
                        //total += Number(subject.marks); 
                        //if(Number(subject.marks)<50){worksheet.getCell(rowStart, colStart+1).value = arrears++; }else{worksheet.getCell(rowStart, colStart+1).value = arrears;}
                    
                    });

                      rowStart++;
                      rowCount++;

                });
                
                worksheet.mergeCells(rowStart, 2, rowStart, 7);
                worksheet.getCell(rowStart, 3).value = "Passed";
                worksheet.mergeCells(rowStart+1, 2, rowStart+1, 7);
                worksheet.getCell(rowStart+1, 3).value = "Failed";
                worksheet.mergeCells(rowStart+2, 2, rowStart+2, 7);
                worksheet.getCell(rowStart+2, 3).value = "Appeared";
                worksheet.mergeCells(rowStart+3, 2, rowStart+3, 7);
                worksheet.getCell(rowStart+3, 3).value = "Percentage";

                let passedmarkstartCol=8;
                Object.values(res.data.failed_results).forEach((passed) => {
                    console.log(passed.students_passed)
                    worksheet.getCell(rowStart, passedmarkstartCol).value = Number(passed.students_passed);
                    passedmarkstartCol++;
                });

                 let failedmarkstartCol=8;
                Object.values(res.data.failed_results).forEach((failed) => {
                    console.log(failed.students_failed)
                    worksheet.getCell(rowStart+1, failedmarkstartCol).value = Number(failed.students_failed);
                    failedmarkstartCol++;
                });

                let appearedstartCol=8;
                Object.values(res.data.failed_results).forEach((appeared) => {
                
                    worksheet.getCell(rowStart+2, appearedstartCol).value = Number(appeared.total_students);
                    appearedstartCol++;
                });

                worksheet.getCell(rowStart+3, 8).value = 
                {
                    formula: `(H${rowStart}/H${rowStart+2})*100`,
                    result: null
                };
                worksheet.getCell(rowStart+3, 9).value = 
                {
                    formula: `(I${rowStart}/I${rowStart+2})*100`,
                    result: null
                };

                worksheet.getCell(rowStart+3, 10).value = 
                {
                    formula: `(J${rowStart}/J${rowStart+2})*100`,
                    result: null
                };

                worksheet.getCell(rowStart+3, 11).value = 
                {
                    formula: `(K${rowStart}/K${rowStart+2})*100`,
                    result: null
                };

                worksheet.getCell(rowStart+3, 12).value = 
                {
                    formula: `(L${rowStart}/L${rowStart+2})*100`,
                    result: null
                };

                worksheet.getCell(rowStart+3, 13).value = 
                {
                    formula: `(M${rowStart}/M${rowStart+2})*100`,
                    result: null
                };

                worksheet.getCell(rowStart+3, 14).value = 
                {
                    formula: `(N${rowStart}/N${rowStart+2})*100`,
                    result: null
                };

                worksheet.getCell(rowStart+3, 15).value = 
                {
                    formula: `(O${rowStart}/O${rowStart+2})*100`,
                    result: null
                };
                let rowstart3=rowStart;
                 //header for staff
                worksheet.getCell(rowStart+6, 2).value = "SL.No";
                worksheet.mergeCells(rowStart + 6, 3, rowStart + 6, 4);
                worksheet.getCell(rowStart+6, 3).value = "Sub. Code";
                
                worksheet.mergeCells(rowStart + 6, 5, rowStart + 6, 13);
                worksheet.getCell(rowStart+6, 5).value = "Subject Title";
                
                worksheet.mergeCells(rowStart + 6, 14, rowStart + 6, 15);
                worksheet.getCell(rowStart+6, 14).value = "Staff Name";
                worksheet.getCell(rowStart+6, 16).value = "Pass %";
                worksheet.mergeCells(rowStart + 6, 17, rowStart + 6, 18);
                worksheet.getCell(rowStart+6, 17).value = "Pass Percentage";

                 let slnoStart=1;
                Object.values(res.data.staffresult).forEach((students) => {
                    let columnStart = 1; 
                    console.log(students.staff_performname);
                    worksheet.getCell(rowStart+7, columnStart + 1).value = slnoStart;
                    worksheet.mergeCells(rowStart + 7, 3, rowStart + 7, 4);
                    worksheet.getCell(rowStart+7, columnStart + 2).value = students.staff_performsubjectcode;
                    worksheet.mergeCells(rowStart + 7, 5, rowStart + 7, 13);
                    worksheet.getCell(rowStart+7, columnStart + 4).value = students.staff_performsubject;
                    worksheet.mergeCells(rowStart + 7, 14, rowStart + 7, 15);
                    worksheet.getCell(rowStart+7, columnStart + 14).value = students.staff_performname;
                    //worksheet.mergeCells(rowStart + 6, 17, rowStart + 6, 18);
                
                     worksheet.getCell(rowStart+7, columnStart + 15).value = Number(students.staff_performpercent);
                      worksheet.mergeCells(rowStart + 7, 17, rowStart + 7, 18);
                    
                     
                    //worksheet.getCell(rowStart2, columnStart).value = students[0].id;
                    //    students.forEach((subject, index) => {
                    //     console.log(subject);
                    //        
                    //    });
                    
                    rowStart++;
                    slnoStart++;
                    
                    
                });

                worksheet.getCell(rowstart3+7, 18).value = 
                {
                    formula: `(H${rowstart3}/H${rowstart3+2})*100`,
                    result: null
                };
                 worksheet.getCell(rowstart3+8, 18).value = 
                {
                    formula: `(I${rowstart3}/I${rowstart3+2})*100`,
                    result: null
                };
                 worksheet.getCell(rowstart3+9, 18).value = 
                {
                    formula: `(J${rowstart3}/J${rowstart3+2})*100`,
                    result: null
                };

                  worksheet.getCell(rowstart3+10, 18).value = 
                {
                    formula: `(K${rowstart3}/K${rowstart3+2})*100`,
                    result: null
                };
                  worksheet.getCell(rowstart3+11, 18).value = 
                {
                    formula: `(L${rowstart3}/L${rowstart3+2})*100`,
                    result: null
                };
                  worksheet.getCell(rowstart3+12, 18).value = 
                {
                    formula: `(M${rowstart3}/M${rowstart3+2})*100`,
                    result: null
                };
                worksheet.getCell(rowstart3+13, 18).value = 
                {
                    formula: `(N${rowstart3}/N${rowstart3+2})*100`,
                    result: null
                };
                worksheet.getCell(rowstart3+14, 18).value = 
                {
                    formula: `(O${rowstart3}/O${rowstart3+2})*100`,
                    result: null
                };


            }   


            const buffer = await workbook.xlsx.writeBuffer();
            const fileBlob = new Blob([buffer], { type: 'application/octet-stream' });
    
            // Trigger the download
            saveAs(fileBlob, 'exported_performance.xlsx');
    
            alert("Export successful!");
           // datecells=['C6','K6','S6','AA6','AI6','AQ6','AY6','BG6','BO6','BW6','CE6','CM6','CU6','DC6','DK6','DS6','EA6','EI6','EQ6','EY6','FG6','FO6','FW6','GE6','GM6','GU6','HC6','HK6','HS6','IA6','II6'];
            //startCells=[2,10,18,26,34,42,50,58,66,74,82,90,98,106,114,122,130,138,146,154,162,170,178,186,194,202,210,218,226,234,242];
            // setStudents(res.data.results);
            // setSubjects(res.data.subjects);
            toast.dismiss(findid); // Dismiss the loading toast when data is fetched successfully
        }
        catch (err) {
            // If request fails
            console.log(err.response);
            if (err.response && err.response.status === 422) {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            } else {
                toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
            }
        }
    }

    useEffect(() => {
        if (!searchData.perform_courseid || !searchData.perform_courseyear || !searchData.perform_semester || !searchData.perform_testtype || !searchData.academic_year) {
            //|| !searchData.perform_month_range
            console.log(searchData);
            setIsButtonEnabled(false);
        } else {
            setIsButtonEnabled(true);
        }
    }, [searchData]);

    const handleChange = (event, studentId, session) => {
        const { value } = event.target;
        // Update attendance data for the selected session and student
        setAttendanceData(prevAttendanceData => ({
            ...prevAttendanceData,
            [`${studentId}_session${session}`]: value
        }));

    };

    const handleperformanceChange = (event, studentId, subjectcode) => {
        const { value } = event.target;
        // Update attendance data for the selected session and student
       

          setPerformanceData(prevPerformanceeData => {
        const updatedData = {
            ...prevPerformanceeData,
            [`${studentId}_${subjectcode}`]: value
        };

        // Initialize an object to store the count of "U" grades for each subject
        const FailGradeCountsBySubject = {};

        // Loop through subjects and count the number of "U" grades in each column
        let studcount=0;
        students.forEach((student) => {
            studcount++
        })
        subjects.forEach((subject) => {
            let FailGradeCount = 0;
            students.forEach((student) => {
                
                const grade = updatedData[`${student.id}_${subject.subjectcode}`] || '';
                if (grade >=50) {
                    FailGradeCount++;
                }
            });
            //alert(studcount)
            FailGradeCountsBySubject[subject.subjectcode] = FailGradeCount*100/studcount;
        });

        console.log("Count of 'U' grades by subject:", FailGradeCountsBySubject);
setgradeBysubject(FailGradeCountsBySubject)
        return updatedData;
    });


    };

    const handleperformanceChangeUexam = (event, studentId, subjectcode) => {
        const { value } = event.target;
        // Update attendance data for the selected session and student
       setPerformanceDataUexam(prevPerformanceDataUexam => {
        const updatedData = {
            ...prevPerformanceDataUexam,
            [`${studentId}_${subjectcode}`]: value
        };

        // Initialize an object to store the count of "U" grades for each subject
        const uGradeCountsBySubject = {};

        // Loop through subjects and count the number of "U" grades in each column
        subjects.forEach((subject) => {
            let uGradeCount = 0;
            students.forEach((student) => {
                const grade = updatedData[`${student.id}_${subject.subjectcode}`] || '';
                if (grade !== 'U') {
                    uGradeCount++;
                }
            });
            uGradeCountsBySubject[subject.subjectcode] = uGradeCount*100/2;
        });

        console.log("Count of 'U' grades by subject:", uGradeCountsBySubject);
setgradeBysubject(uGradeCountsBySubject)
        return updatedData;
    });
      
        

    };
useEffect(() => {
        // Update U-grade counts whenever performanceDataUexam changes
        updateUgradeCounts();
    }, [performanceDataUexam]);

    useEffect(() => {
        // Update U-grade counts whenever performanceDataUexam changes
        updateFailgradeCounts();
    }, [performanceData]);
const updateFailgradeCounts = () => {
        // Calculate the count of "U" grades for each student
        const newUGradeCounts = {};
        students.forEach(student => {
            const uCount = subjects.reduce((count, subject) => {
                const performanceValue = performanceData[`${student.id}_${subject.subjectcode}`] || '';
                //alert(performanceValue)
                if (performanceValue < 50) {
                    count++;
                }
                return count;
            }, 0);
            newUGradeCounts[student.id] = uCount;
        });
        // Update the counts in the state
        setUGradeCounts(newUGradeCounts);
    };
   const updateUgradeCounts = () => {
        // Calculate the count of "U" grades for each student
        const newUGradeCounts = {};
        students.forEach(student => {
            const uCount = subjects.reduce((count, subject) => {
                const performanceValue = performanceDataUexam[`${student.id}_${subject.subjectcode}`] || '';
                if (performanceValue === 'U') {
                    count++;
                }
                return count;
            }, 0);
            newUGradeCounts[student.id] = uCount;
        });
        // Update the counts in the state
        setUGradeCounts(newUGradeCounts);
    };

 const handleSearchChange = (e) => {
    const { name, value } = e.target;

    setSearchData(prevSearchData => {
        const newSearchData = {
            ...prevSearchData,
            [name]: value,
        };

        if (newSearchData.perform_testtype === "University Exam") {
            setIsUniversityExam(true); // Update state variable if value is "University Exam"
        } else {
            setIsUniversityExam(false); // Reset state variable if value is not "University Exam"
        }

        //alert(newSearchData.perform_testtype);

        return newSearchData;
    });
};

        const handleSearch=(e) => {
    e.preventDefault();
    console.log(searchData);
   // return false
    fetchStudents()
}

    const handleDateChange = (selectedDates, dateField) => {
        const selectedDate = selectedDates[0];
        let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
        formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [dateField]: formattedDate,
        }));
    };
 const handleUexamSubmit = (event) => {
        event.preventDefault();
        // Handle submission of attendance data
        console.log(performanceData);
        //return false;
        const dataToSubmit = {
        ...performanceDataUexam,
        ...searchData,
        ...gradebysubject
    };

    // Handle submission of attendance data
    console.log(dataToSubmit);
    dataToSubmit['perform_month_range']=searchData.perform_month_range[0]+" to "+searchData.perform_month_range[1]
    console.log(dataToSubmit);
    //return false;
    SendData(dataToSubmit);
     //   SendData()
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle submission of attendance data
        console.log(performanceData);
        //return false;
        const dataToSubmit = {
        ...performanceData,
        ...searchData,
         ...gradebysubject
    };

    // Handle submission of attendance data
    console.log(dataToSubmit);
    dataToSubmit['perform_month_range']=searchData.perform_month_range[0]+" to "+searchData.perform_month_range[1]
    console.log(dataToSubmit);
    //return false;
    SendData(dataToSubmit);
     //   SendData()
    };

    const SendData = async(dataToSubmit) =>{
     const id =  toast.loading("Saving Performance data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
        const res = await axios.post('/api/performanceadd', dataToSubmit);

        // If request is successful
        console.log(res.data);
        toast.update(id, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        navigate('/studentperformance');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(id, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }

  const generateYearOptions = () => {
 
   
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(1);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};

    return (
        <>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Generate Performance Report</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form >
                                <Row className="align-items-center">
                                    <Col sm="4">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="perform_courseid"
                                                id="perform_courseid"
                                                className="selectpicker form-control"
                                                value={searchData.perform_courseid}
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Select Course</option>
                                                {studentData.coursenames.data.results.map((course, index) => (
                                                    <option key={index} value={course.id}>
                                                        {course.coursename}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="academic_year"
                                                id="academic_year"
                                                className="selectpicker form-control"
                                                data-style="py-0"
                                                value={searchData.academic_year}
                                                onChange={handleSearchChange}
                                            >
                                                <option value="">Academic Year</option>
                                                {generateYearOptions()}
                                            </select>
                                         </Form.Group>
                                    </Col>
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select name="perform_courseyear" id="perform_courseyear" className="selectpicker form-control" data-style="py-0" value={searchData.perform_courseyear} onChange={handleSearchChange}>
                                                <option value=''>Select Year</option>
                                                <option>I</option>
                                                <option>II</option>
                                                <option>III</option>
                                                <option>IV</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="3">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select name="perform_semester" id="perform_semester" className="selectpicker form-control" data-style="py-0" value={searchData.perform_semester} onChange={handleSearchChange}>
                                                <option value=''>Select Semester</option>
                                                {semesterOptions[searchData.perform_courseyear] &&
                                                    semesterOptions[searchData.perform_courseyear].map((semester, index) => (
                                                        <option key={index}>{semester}</option>
                                                    ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="3">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select name="perform_testtype" id="perform_testtype" className="selectpicker form-control" data-style="py-0" value={searchData.perform_testtype} onChange={handleSearchChange}>
                                                <option value=''>Select Test Type</option>
                                                <option>Class Test-I</option>
                                                <option>Cycle Test - I</option>
                                                <option>IA - I</option>
                                                <option>Class Test-II</option>
                                                <option>Cycle Test-II</option>
                                                <option>IA - II</option>
                                                <option>Coaching - I</option>
                                                <option>Coaching - II</option>
                                                <option>Coaching - III</option>
                                                <option>Coaching - IV</option>
                                                <option>Coaching - V</option>
                                                <option>model - I</option>
                                                <option>Model - II</option>
                                                <option>University Exam</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                      <Col sm="4" className='mt-4'>
            <Form.Group className="mb-3 mb-sm-0">
              <Flatpickr
    id="perform_month_range"
    name="perform_month_range"
    value={searchData.perform_month_range ? searchData.perform_month_range.map(dateStr => new Date(dateStr)) : []}
    onChange={handleMonthRangeChange}
    options={{
        mode: "range",
        dateFormat: 'M Y',
        altInput: true,
        altFormat: 'M Y',
        maxDate: new Date(new Date().getFullYear(), 11),
        plugins: [new monthSelectPlugin({ shorthand: true })],
        onReady: function (selectedDates, dateStr, instance) {
            const monthContainers = instance.calendarContainer.querySelectorAll('.flatpickr-months');
            monthContainers.forEach(container => {
                container.style.cssText = `
                    display: flex !important;
                    flex-wrap: wrap !important;
                    justify-content: space-between !important;
                    width: 100% !important;
                    margin: 0 -5px !important;
                `;
            });
        }
    }}
    className="form-control"
/>
            </Form.Group>
        </Col>
         <Col sm="2">
                                        <Form.Group className="mt-4">
                                            <select name="perform_testoption" id="perform_testoption" className="selectpicker form-control" data-style="py-0" value={searchData.perform_testoption} onChange={handleSearchChange}>
                                                <option value=''>Select</option>
                                                <option>Normal</option>
                                                <option>Re-test</option>
                                            </select>
                                        </Form.Group>
                                         
                                    </Col>
                                    {/* <Col md="2">
                                            <Form.Group as={Row} className="mt-4">
                                               
                                                <Col sm="12">
                                                    <Form.Control name="studentid" id="studentid" placeholder="Enter Student ID" value={searchData.studentid} onChange={handleSearchChange} required />
                                                </Col>
                                            </Form.Group>
                                        </Col> */}
                                    <Col sm="1" className='mt-4'>
                                        <div className="text-end">
                                            <Button type="button" id="searchbutton" variant="primary" onClick={handleSearch} disabled={!isButtonEnabled}>Proceed</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                {/* <Col sm="12" style={{fontSize:'14px'}}>
                    <Card>
                        <Card.Body>
                           
                         
                            <Row className='mb-2' style={{backgroundColor:'#2664CF',color:'#fff',padding:'4px'}}>
                                
                               
                                <Col sm="1"><b>Sl. No.</b></Col>
                                <Col sm="2"><b>Subject Code</b></Col>
                                <Col sm="4"><b>Subject Title</b></Col>
                                <Col sm="2"><b>Staff Name</b></Col>
                                <Col sm="3"><b>Result %</b></Col>
                            </Row>
                               {subjects.map((subject, subjectIndex) => (
                                 <Row className='mb-2' style={{backgroundColor:'#fff',color:'#000',padding:'4px'}}>
                                    <Col sm="1">{subjectIndex+1}</Col>
                                        <Col key={subject} sm="2">
                                            <b>{subject.subjectcode}</b>
                                        </Col>
                                        <Col key={subject} sm="4" className='text-start'>
                                            <b>{subject.subjectname}</b>
                                        </Col>
                                        <Col key={subject} sm="2" className='text-start'>
                                            <b>{subject.subjecthandledbyname}</b>
                                        </Col>
                                        <Col key={subject} sm="2" className='text-center'>
                                            <input className='text-center' name={subject.subjectname} value={gradebysubject[subject.subjectcode]} disabled></input>
                                        </Col>
                                </Row>
                                    ))}
                        </Card.Body>
                    </Card>
                </Col> */}
            </Row>
              {isUniversityExam ? (
                /* University Exam Form */
                 <Row>
                <Col sm="12">
                    {/* <Card>
                        <Card.Body>
                            <Form onSubmit={handleUexamSubmit}>
                                <h5 className='text-start' >Performance Input Form (University Exam)</h5>
                                <Row className='mb-2' style={{backgroundColor:'#2664CF',color:'#fff',padding:'4px'}}>
                                    <Col sm="2" className='text-start'>
                                            <b style={{fontSize:'14px'}}>Student Name</b>
                                    </Col>
                                     {subjects.map((subject, subjectIndex) => (
                                        <Col key={subject} sm="1">
                                            <b style={{fontSize:'14px'}}>{subject.subjectcode}</b>
                                        </Col>
                                    ))}
                                    <Col sm="2">
                                            <b style={{fontSize:'14px'}}>Current Arrear Status</b>
                                    </Col>
                                     <Col sm="2">
                                            <b style={{fontSize:'14px'}}>Previous Arrear Status</b>
                                    </Col>
                                 

                                </Row>
                                {students.map((student, studentIndex) => (
                                    <div key={student.id}>
                                        <Row className='mb-3' style={{borderBottom:'1px solid grey',paddingBottom:'6px'}}>
                                         
                                            <Col sm="2" className='mt-2 text-start'>
                                                <h6>{student.first_name}</h6>
                                            </Col>
                                            {subjects.map((subject, sessionIndex) => {
                                                const performanceKey = `perform_marks_${studentIndex}_${sessionIndex + 1}`;
                                                const performanceValue = performanceDataUexam[`${student.id}_${subject.subjectcode}`] || "";
                                                
                                                return (
                                                    <Col key={sessionIndex} sm="1">
                                                        <Form.Group>
                                                            <select 
                                                                name={performanceKey}  
                                                                id={performanceKey}  
                                                                className="selectpicker form-control text-center" 
                                                                data-style="py-0" 
                                                                onChange={(event) => handleperformanceChangeUexam(event, student.id, subject.subjectcode)}
                                                                value={performanceValue}
                                                            >
                                                                <option value=''>Select</option>
                                                                <option>A+</option>
                                                                <option>A</option>
                                                                <option>B</option>
                                                                <option>C</option>
                                                                <option>U</option>
                                                            </select>
                                                        </Form.Group>
                                                    </Col>
                                                );
                                            })}
                                           
                                             <Col sm="1">
                                                <div className="text-center d-flex align-items-center mt-2">
                                                   
                                                    {uGradeCounts[student.id] || 0}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Button type="submit">Save</Button>
                            </Form>
                        </Card.Body>
                    </Card> */}
                </Col>
            </Row>
            ) : (
                /* Regular Performance Input Form */
                <Row>
                <Col sm="12">
                    {/* <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <h5 className='text-start' >Performance Input Form (Internal Tests)</h5>
                                <Row className='mb-2' style={{backgroundColor:'#2664CF',color:'#fff',padding:'4px'}}>
                                    <Col sm="3">
                                            <b style={{fontSize:'14px'}}>Student Name</b>
                                    </Col>
                                     {subjects.map((subject, subjectIndex) => (
                                        <Col key={subject} sm="1">
                                            <b style={{fontSize:'14px'}}>{subject.subjectcode}</b>
                                        </Col>
                                    ))}
                                    
                                    <Col sm="1">
                                            <b style={{fontSize:'14px'}}>Status</b>
                                    </Col>
                                  

                                </Row>
                                {students.map((student, studentIndex) => (
    <div key={student.id}>
        <Row className='mb-3' style={{borderBottom:'1px solid grey',paddingBottom:'6px'}}>
            <Col sm="3">
                <h6>{student.first_name}</h6>
            </Col>
            {subjects.map((subject, sessionIndex) => {
                const performanceKey = `perform_marks_${studentIndex}_${sessionIndex + 1}`;
                const performanceValue = performanceData[`${student.id}_${subject.subjectcode}`] || 0;
                const fail = performanceValue < 50;
                const pass = performanceValue >= 50;
                return (
                    <Col key={sessionIndex} sm="1">
                        <Form.Group>
                            <input 
                                style={{width:'78px'}}
                                type="number" 
                                name={performanceKey} 
                                id={performanceKey} 
                                className="form-control text-center" 
                                placeholder="Marks" 
                                min="0" 
                                max="100" 
                                step="1"   
                                onChange={(event) => handleperformanceChange(event, student.id, subject.subjectcode)} />
                        </Form.Group>
                    </Col>
                );
            })}
           
            
            <Col sm="1">
                <div className="text-center">
                 
                    {subjects.every(subject => performanceData[`${student.id}_${subject.subjectcode}`] >= 50) ? (
                        <p className="text-success">PASS</p>
                    ) : (
                        <p className="text-danger">FAIL</p>
                    )}
                </div>
            </Col>
        </Row>
    </div>
))}
                                <Button type="submit">Save</Button>
                            </Form>
                        </Card.Body>
                    </Card> */}
                </Col>
            </Row>
            )}
           
        </>
    );

}

export default PerformanceReportLandingMain;

import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Card from '../../../components/defaultSnippets/Card';
import { useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function BillingReportLandingMain() {
    
    const [courses, setCourses] = useState('');
    const [searchData, setSearchData] = useState({
        billtype: '', courseid: '', current_year: '', academic_year: '', studentid: ''
    });
    const currentYear = new Date().getFullYear(); // Get the current year
  const startYear = 2020; // Define the start year for the dropdown
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = [];
  for (let i = currentYear; i >= startYear; i--) {
    years.push(i);
  }

    // Function to generate options for past four years
const generateYearOptions = () => {
 
   
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(1);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};
  const handleyearChange = (event) => {
    setSelectedYear(event.target.value); // Update the selected year
  };


    const fetchCourses = async () => {
        const findid = toast.loading("Fetching Courses data...", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
        try {
            const res = await axios.get('/api/attendancereportmain');
            setCourses(res.data.results);
            toast.dismiss(findid); // Dismiss the loading toast when data is fetched successfully
        }
        catch (err) {
            console.log(err.response);
            toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    const handleDateChange = (selectedDates, dateField) => {
        const selectedDate = selectedDates[0];
        let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
        formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [dateField]: formattedDate,
        }));
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        console.log(name+"->"+value)
        setSearchData(prevSearchData => ({
            ...prevSearchData,
            [name]: value,
        }));
    };

    

    const handleSearch = (e) => {
        e.preventDefault();
       
        if (searchData.courseid !== ""  && searchData.current_year !== "" && searchData.academic_year!="") {
             
            if(searchData.billtype=="" && searchData.studentid==""){
                toast.error("Please select Type or Enter Student ID to continue", {
                    position: "bottom-right",
                    autoClose: 3000,
                    theme: "dark",
                });
                return;
            }
            else
            {
               fetchReport();
            }
           
        } else {
            // Handle missing fields
            toast.error("Please complete all required fields!", {
                position: "bottom-right",
                autoClose: 3000,
                theme: "dark",
            });
        }
    };

    const fetchReport = async () => {
        const findid = toast.loading("Fetching report data...", {
            position: "bottom-right",
            autoClose: 3000,
            theme: "dark",
        });

        try {
            const res = await axios.get('/api/billingreportgenerate', {
                params: searchData // Pass searchData as query parameters
            });
           
            console.log(res);
           // return false;
            let response='';
            if(searchData.billtype=="academic")
            {
                response = await fetch(`${process.env.PUBLIC_URL}/academicfeesoverallreport.xlsx`);

            }
            else if(searchData.billtype=="hostel")
            {
                response = await fetch(`${process.env.PUBLIC_URL}/hostelfeesoverallreport.xlsx`);
            }
            else if(searchData.billtype=="bus")
            {
                response = await fetch(`${process.env.PUBLIC_URL}/busfeesoverallreport.xlsx`);
            }
            else if(searchData.studentid!="")
            {
                  response = await fetch(`${process.env.PUBLIC_URL}/feesstudentspecificreport.xlsx`);
            }

            const workbook = new ExcelJS.Workbook();

            const blob = await response.blob();

            // Load the workbook from the blob
            await workbook.xlsx.load(blob);
            
            const worksheet = workbook.getWorksheet(1); // Get the first worksheet
            console.log(res);
            

            if(searchData.billtype=="academic")
            {
                const dataArray = Object.values(res.data.finalresult);
                let rowStart = 6; let slnocount=1;
                  Object.values(dataArray).forEach((student, index) => {
                    let columnStart = 1; // Reset column for each new row
                    worksheet.getCell(rowStart, columnStart++).value = slnocount;
                    worksheet.getCell(rowStart, columnStart++).value = student.studentid;
                    worksheet.getCell(rowStart, columnStart++).value = student.studentname;
                    worksheet.getCell(rowStart, columnStart++).value = student.invoicebranch;
                    worksheet.getCell(rowStart, columnStart++).value = student.invoiceyear;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_admissionfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_tutionfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_specialfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_labfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_libraryfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_physedfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_otherfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_machineriesfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_compfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_examfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_penaltyfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_miscfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.totalfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_tutionfee+student.total_admissionfee+student.total_specialfee+student.total_labfee+student.total_libraryfee+student.total_physedfee+student.total_otherfee+student.total_machineriesfee+student.total_compfee+student.total_examfee+student.total_penaltyfee+student.total_miscfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.totalfee-(student.total_tutionfee+student.total_admissionfee+student.total_specialfee+student.total_labfee+student.total_libraryfee+student.total_physedfee+student.total_otherfee+student.total_machineriesfee+student.total_compfee+student.total_examfee+student.total_penaltyfee+student.total_miscfee);
                    
                    rowStart++; // Move to the next row for the next student
                    slnocount++;
                })
            }
            else if(searchData.billtype=="hostel")
            {
                const dataArray = Object.values(res.data.finalresult);
                let rowStart = 6; let slnocount=1;
                  Object.values(dataArray).forEach((student, index) => {
                    let columnStart = 1; // Reset column for each new row
                    worksheet.getCell(rowStart, columnStart++).value = slnocount;
                    worksheet.getCell(rowStart, columnStart++).value = student.studentid;
                    worksheet.getCell(rowStart, columnStart++).value = student.studentname;
                    worksheet.getCell(rowStart, columnStart++).value = student.invoicebranch;
                    worksheet.getCell(rowStart, columnStart++).value = student.invoiceyear;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_hostelfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_hostelfee_paid;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_hostelfee_balance;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_messfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_messfee_paid;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_messfee_balance;
                    
                    rowStart++; // Move to the next row for the next student
                    slnocount++;
                })
            }
            else if(searchData.billtype=="bus")
            {
                const dataArray = Object.values(res.data.finalresult);
                let rowStart = 6; let slnocount=1;
                  Object.values(dataArray).forEach((student, index) => {
                    let columnStart = 1; // Reset column for each new row
                    worksheet.getCell(rowStart, columnStart++).value = slnocount;
                    worksheet.getCell(rowStart, columnStart++).value = student.studentid;
                    worksheet.getCell(rowStart, columnStart++).value = student.studentname;
                    worksheet.getCell(rowStart, columnStart++).value = student.invoicebranch;
                    worksheet.getCell(rowStart, columnStart++).value = student.invoiceyear;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_busfee;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_busfee_paid;
                    worksheet.getCell(rowStart, columnStart++).value = student.total_busfee_balance;
                    rowStart++; // Move to the next row for the next student
                    slnocount++;
                })
            }
            else
            {
               
                const dataArray = Object.values(res.data.result_invoice);
               
                // alert("coming1");
                

                let rowStart = 6; 
                let slnocount = 1;

                // Iterate over each student object in the data array
                dataArray.forEach((student) => {
                    console.log(student);
                    // Access the invoices array for the current student
                    student.invoices.forEach((invoice) => {
                         console.log(invoice);
                    let columnStart = 1; // Reset column for each new row
                    worksheet.getCell(rowStart, columnStart++).value = slnocount;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.studentid;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.studentname;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.invoicebranch;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.invoiceyear;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.invoiceno;
                    worksheet.getCell(rowStart, columnStart++).value = '';
                    worksheet.getCell(rowStart, columnStart++).value = invoice.admissionfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.tutionfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.specialfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.labfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.libraryfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.physedfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.otherfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.machineriesfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.compfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.examfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.penaltyfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.miscfee;
                  //  worksheet.getCell(rowStart, columnStart++).value = invoice.totalfee;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.toalfee_paid;
                    worksheet.getCell(rowStart, columnStart++).value = invoice.totalfee_balance;
                    
                    rowStart++; // Move to the next row for the next student
                    slnocount++;
                })
            });

            // //bus
             const worksheet2 = workbook.getWorksheet(2); // Get the first worksheet

            const dataArray2 = Object.values(res.data.results_busfee);
             console.log(dataArray2.length);
             if(dataArray2.length > 0)
             {
                let rowStart2 = 6; 
                let slnocount2 = 1;

                // Iterate over each student object in the data array
                dataArray2.forEach((bus) => {
                    // Access the invoices array for the current student
                    bus.invoices.forEach((inv) => {
                    let columnStart = 1; // Reset column for each new row
                    worksheet2.getCell(rowStart2, columnStart++).value = slnocount2;
                    worksheet2.getCell(rowStart2, columnStart++).value = inv.invoicetoid;
                    worksheet2.getCell(rowStart2, columnStart++).value = inv.studentname;
                    worksheet2.getCell(rowStart2, columnStart++).value = bus.invoicebranch;
                    worksheet2.getCell(rowStart2, columnStart++).value = bus.invoiceyear;
                    worksheet2.getCell(rowStart2, columnStart++).value = inv.invoiceno;
                    worksheet2.getCell(rowStart2, columnStart++).value = inv.toalfee_paid;
                    worksheet2.getCell(rowStart2, columnStart++).value = inv.totalfee_balance;
                    
                    
                    rowStart2++; // Move to the next row for the next student
                    slnocount2++;
                        })
                    });
             }
             

            // //Hostel & Mess
             const worksheet3 = workbook.getWorksheet(3); // Get the first worksheet
            const dataArray3 = Object.values(res.data.results_hostelfee);
            //console.log(dataArray3);
                let rowStart3 = 6; 
                let slnocount3 = 1;
                  dataArray3.forEach((hostel) => {
                    // Access the invoices array for the current student
                    hostel.invoices.forEach((inv) => {
                    let columnStart = 1; // Reset column for each new row
                    worksheet3.getCell(rowStart3, columnStart++).value = slnocount3;
                    worksheet3.getCell(rowStart3, columnStart++).value = inv.invoicetoid;
                    worksheet3.getCell(rowStart3, columnStart++).value = inv.invoicetoname;
                    worksheet3.getCell(rowStart3, columnStart++).value = hostel.invoicebranch;
                    worksheet3.getCell(rowStart3, columnStart++).value = hostel.invoiceyear;
                    worksheet3.getCell(rowStart3, columnStart++).value = inv.invoiceno;
                    worksheet3.getCell(rowStart3, columnStart++).value = inv.totalfee_hostel;
                    worksheet3.getCell(rowStart3, columnStart++).value = inv.toalfee_hostel_paid;
                    worksheet3.getCell(rowStart3, columnStart++).value = inv.totalfee_hostel_balance;
                    worksheet3.getCell(rowStart3, columnStart++).value = inv.totalfee_mess;
                    worksheet3.getCell(rowStart3, columnStart++).value = inv.toalfee_mess_paid;
                    worksheet3.getCell(rowStart3, columnStart++).value = inv.totalfee_mess_balance;
                    
                    
                    rowStart3++; // Move to the next row for the next student
                    slnocount3++;
                })
            });

            }

            const buffer = await workbook.xlsx.writeBuffer();
            const fileBlob = new Blob([buffer], { type: 'application/octet-stream' });
            if(searchData.billtype=="bus")
            {
                saveAs(fileBlob, 'exported_Billing_report_Bus.xlsx');
            }
            else if(searchData.billtype=="hostel")
            {
                saveAs(fileBlob, 'exported_Billing_report_Hostel.xlsx');
            }
            if(searchData.billtype=="academic")
            {
                saveAs(fileBlob, 'exported_Billing_report_Academic.xlsx');
            }
            else
            {
                 saveAs(fileBlob, 'exported_overall_Billing_report.xlsx');
            }
            
            // Trigger the download
            
    
            alert("Export successful!");
             toast.dismiss(findid);

        } catch (err) {
            console.log(err.response);
            toast.update(findid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    };

    return (
        <>
            <ToastContainer />
            <Row>
                <Col sm="12" lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Generate Billing Report</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="align-items-center">
                                    <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="billtype"
                                                id="billtype"
                                                className="form-control"
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Select Type</option>
                                                <option value='bus'>Bus</option>
                                                <option value='hostel'>Hostel</option>
                                                <option value='academic'>Academic</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    
                                    <Col sm="3">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="courseid"
                                                id="courseid"
                                                className="form-control"
                                                onChange={handleSearchChange}
                                            >
                                                <option value=''>Select Course</option>
                                                {Object.entries(courses).map(([key, course], index) => (
                                                    <option key={index} value={course.id}>
                                                        {course.coursename}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="1">
                                            <Form.Group className="mb-3 mb-sm-0">
                                            {/* <Form.Label>Current Year:</Form.Label> */}
                                            <select name="current_year" id="current_year" className="selectpicker form-control" data-style="py-0"  value={searchData.current_year} onChange={handleSearchChange}>
                                            <option value="">Year</option>
                                            <option>I</option>
                                            <option>II</option>
                                            <option >III</option>
                                            <option >IV</option>
                                            </select>
                                        </Form.Group>
                                    </Col>
                                     <Col sm="2">
                                        <Form.Group className="mb-3 mb-sm-0">
                                            <select
                                                name="academic_year"
                                                id="academic_year"
                                                className="selectpicker form-control"
                                                data-style="py-0"
                                                value={searchData.academic_year}
                                                onChange={handleSearchChange}
                                            >
                                                <option value="">Academic Year</option>
                                                {generateYearOptions()}
                                            </select>
                                         </Form.Group>
                                    </Col>
                                    {/* Conditionally render att_date or att_month based on att_type */}
                                   

                                     

                                         <Col md="2">
                                            <Form.Group as={Row} className="mb-3 mb-sm-0">
                                                {/* <Form.Label column sm="2" htmlFor="email" className="control-label text-start">Student ID:</Form.Label> */}
                                                <Col sm="12">
                                                    <Form.Control name="studentid" id="studentid" placeholder="Enter Student ID" value={searchData.studentid} onChange={handleSearchChange} required />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    
                                    <Col sm="1">
                                        <div className="text-end">
                                            <Button type="button" id="searchbutton" variant="primary" onClick={handleSearch}>Generate</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default BillingReportLandingMain;
